/* eslint-disable no-console */

export function consoleMessage() {

  const heading = `font-size: 14px; font-weight: bold; margin: 9px 0 3px 0;`;
  console.log('%cSpotted an issue? 💻', heading);
  console.log(
    'Please don\'t hesitate to contact us at:\ntech@acat.cc',
  );
}

