export function oscarFunctions() {
  // Auto-submit (hidden) search form when selecting a new sort-by option
  $('#id_sort_by').on('change', function () {
    $(this).closest('form').trigger("submit");
  });
  // Bind events to facet checkboxes
  $('.facet_checkbox').on('change', function () {
    window.location.href = $(this).nextAll('.facet_url').val();
  });
}
