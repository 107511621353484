// Slider for horizontally scrolling .content--h-scroll elements
export function gridSlider() {
  var instance = $(".content--h-scroll");
  $.each(instance, function (key, value) {

    var arrows = $(instance[key]).find(".arrow"),
      prevArrow = arrows.filter('.arrow-prev'),
      nextArrow = arrows.filter('.arrow-next'),
      list = $(instance[key]).find("ul"),
      x = 0,
      mx = 0,
      maxScrollWidth = list[0].scrollWidth - (list[0].clientWidth / 2) - (list.width() / 2);

    $(arrows).on('click', function () {

      if ($(this).hasClass("arrow-next")) {
        x = ((list.width() / 2)) + list.scrollLeft();
        list.animate({
          scrollLeft: x,
        })
      } else {
        x = ((list.width() / 2)) - list.scrollLeft();
        list.animate({
          scrollLeft: -x,
        })
      }

    });

    $(list).on({
      mousemove: function (e) {
        var mx2 = e.pageX - this.offsetLeft;
        if (mx) this.scrollLeft = this.sx + mx - mx2;
      },
      mousedown: function (e) {
        this.sx = this.scrollLeft;
        mx = e.pageX - this.offsetLeft;
      },
      scroll: function () {
        toggleArrows();
      },
      // 'mousewheel DOMMouseScroll': function () {
      // }
    });

    $(document).on("mouseup", function () {
      mx = 0;
    });

    function toggleArrows() {
      if (list.scrollLeft() > maxScrollWidth - 10) {
        // disable next button when right end has reached 
        nextArrow.addClass('disabled');
      } else if (list.scrollLeft() < 10) {
        // disable prev button when left end has reached 
        prevArrow.addClass('disabled')
      } else {
        // both are enabled
        nextArrow.removeClass('disabled');
        prevArrow.removeClass('disabled');
      }
    }

  });
}