import { consoleMessage } from './components/console-message';
import { dism4Alert } from "./components/dism4-alert";
import { drop4 } from "./components/drop4";
import { externalLinks } from "./components/external-links";
// import { formSubmit } from "./components/form-submit";
import { generalFunctions } from "./components/general-functions";
import { hcaptchaCallback } from "./components/hcaptcha-callback";
import { htmxFunctions } from './components/htmx';
import { languageSelector } from "./components/language-selector";
import { oscarFunctions } from "./components/oscar-functions";
import { preloadFunction } from "./components/preload";
import { scrollShadow } from "./components/sticky-header-scroll-shadow";
// import { slickSlider } from "./components/slick-slider";
import { gridSlider } from "./components/grid-slider";
import { searchInputFocus } from './components/search-modal';
import { starRating } from "./components/star-rating";
// import { svgSprite } from "./components/svg-sprite";
import { toastsList } from './components/bs-toasts-list';
// import { popoverLists } from './components/bs-popover-lists';
import { formValidation } from './components/bs-form-validation';
import { recommenderFunctions } from './components/recommender-functions';

// Media Chrome web component https://www.media-chrome.org
import 'media-chrome';
// https://www.media-chrome.org/docs/en/media-elements/youtube-video
import 'youtube-video-element';

// run these first
generalFunctions();
// svgSprite();

document.addEventListener('DOMContentLoaded', () => {
  /* eslint-disable no-new, no-restricted-syntax */
  consoleMessage();
  dism4Alert();
  drop4();
  externalLinks();
  hcaptchaCallback();
  htmxFunctions();
  languageSelector();
  oscarFunctions();
  preloadFunction();
  recommenderFunctions();
  searchInputFocus();
  scrollShadow();
  gridSlider();
  starRating();
  toastsList();
  formValidation();
});

// re-init after HTMX swap
document.addEventListener('htmx:afterSwap', (evt) => {
  externalLinks(evt);
  drop4(evt);
  dism4Alert(evt);
  languageSelector(evt);
  oscarFunctions(evt);
  recommenderFunctions(evt);
  gridSlider(evt);
  starRating(evt);
});
