export function htmxFunctions() {

  document.body.addEventListener('htmx:onLoad', (target) => {
    bootstrap.init(target);
  });

  document.body.addEventListener('htmx:afterSwap', (evt) => {
    // unfocus search form after submit
    const searchform = htmx.find("#sitesearch");
    searchform.blur(evt);
  });

  // htmx request headers hx-* not allowed by Access-Control-Allow-Headers in preflight response
  // https://github.com/bigskysoftware/htmx/issues/779#issuecomment-1019373147
  document.addEventListener('htmx:configRequest', (evt) => {
    evt.detail.headers = [];
  });

}
