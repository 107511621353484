export function scrollShadow() {
  // https://ryanmulligan.dev/blog/sticky-header-scroll-shadow/
  const navbar = document.getElementById("navbar");
  const intercept = document.createElement("div");

  if (navbar != null) {
    intercept.setAttribute("data-observer-intercept", "");
    intercept.setAttribute("aria-hidden", "true");
    navbar.before(intercept);

    const observer = new IntersectionObserver(([entry]) => {
      navbar.classList.toggle("active", !entry.isIntersecting);
    });

    observer.observe(intercept);
  }
}
